import React from 'react';
import Image from "next/image";
import {useRouter} from "next/router";
import {en} from "../../locales/en";
import {ru} from "../../locales/ru";
import {MenuItem, PartnersListType} from "../../utils/PropsTypes";

const PartnersSection = () => {
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    return (
        <section className={"section-white"}>
            <h2>{t.partners.title}</h2>
            <ul className={"partners-opportunities"}>
                {
                    t.partners.list &&
                    t.partners.list.map((item: PartnersListType, index: React.Key) =>
                        <li key={index}>
                            <div className={"partners-opportunities__img-container"}>
                                <Image
                                    height={48}
                                    width={48}
                                    src={item.src}
                                    alt={item.alt}
                                />
                            </div>
                            <div>
                                <p className={"partners-opportunities__title"}>
                                    {item.title}
                                </p>
                                <p className={"body-text"}>
                                    {item.text}
                                </p>
                            </div>
                        </li>
                    )
                }

                <li>
                    <p className={"doc-title"}>{t.partners.documentation.title}</p>
                    <div className={"doc-link-list"}>
                        {
                            t.partners.documentation.list && t.partners.documentation.list.map((item: MenuItem, key: React.Key) =>
                                <div key={key} className={"doc-link"}>
                                    <div className={"doc-link__icon"}>
                                        <Image
                                            height={16}
                                            width={16}
                                            src={"/doc-green-icon.svg"}
                                        />
                                    </div>
                                    <p>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                        >
                                            {item.title}
                                        </a>
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </li>
            </ul>

            <p className={"services__title service_notice"}>
                {t.partners.description}
            </p>
        </section>
    );
};

export default PartnersSection;